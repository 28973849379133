import type { MetaFunction } from "@remix-run/node";
import { Form, json, useLoaderData } from "@remix-run/react";
import { Suspense } from "react";
import { ComplaintsByYear } from "~/components/complaints-by-year";
import { ComplaintsByYearCategory } from "~/components/complaints-by-year-category";
import { CrashStatistics } from "~/components/crash-statistics";
import { StateStatistics } from "~/components/state-statistics";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { getStatistics } from "~/data/.server/business";

export const meta: MetaFunction = () => {
  return [
    { title: "MovingData.org" },
    {
      name: "description",
      content:
        "Discover comprehensive public information on moving companies at MovingData.org. Our platform provides valuable insights to help you make informed decisions for your next move.",
    },
  ];
};

export const loader = async () => {
  const {
    complaintsByYear,
    complaintsPerStateCurrentYear,
    complaintsByYearCategory,
    crashStats,
    companiesPerState,
    crashesPerState,
  } = await getStatistics();

  return json(
    {
      complaintsByYear,
      complaintsPerStateCurrentYear,
      complaintsByYearCategory,
      crashStats,
      companiesPerState,
      crashesPerState,
    },
    { headers: { "Cache-Control": "public, s-maxage=60" } },
  );
};

export default function Index() {
  const {
    complaintsByYear,
    complaintsPerStateCurrentYear,
    complaintsByYearCategory,
    crashStats,
    companiesPerState,
    crashesPerState,
  } = useLoaderData<typeof loader>();
  return (
    <article className="space-y-10">
      <section className="flex flex-col items-center gap-3 py-10">
        <h2 className="text-3xl font-semibold">
          Know what you are getting into!
        </h2>
        <h3 className="text-2xl text-foreground/75">
          Find out more about the moving company that is carrying your stuff.
        </h3>
        <Form
          action="/search"
          className="flex items-center justify-center gap-2 pt-4"
        >
          <Input
            name="q"
            className="w-[60vw] bg-white dark:bg-black md:w-[500px]"
            autoComplete="off"
            type="text"
            role="search"
            placeholder="Moving Van Line Inc"
          />
          <Button>Search</Button>
        </Form>
      </section>
      <Suspense>
        <section className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <ComplaintsByYearCategory data={complaintsByYearCategory} />
          <div className="col-span-1 flex flex-col gap-4">
            <CrashStatistics
              data={{
                fatal: parseInt(crashStats.fatal),
                tow: parseInt(crashStats.tow),
                injury: parseInt(crashStats.injury),
                total: parseInt(crashStats.total),
              }}
            />

            <ComplaintsByYear data={complaintsByYear} />
          </div>

          <div className="md:col-span-2">
            <StateStatistics
              data={{
                complaints: complaintsPerStateCurrentYear,
                companies: companiesPerState,
                crashes: crashesPerState,
              }}
            />
          </div>
        </section>
      </Suspense>
    </article>
  );
}
