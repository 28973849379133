import { Suspense } from "react";
import { Label, Pie, PieChart, Sector } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartStyle,
  ChartTooltip,
  ChartTooltipContent,
} from "~/components/ui/chart";

const config = {
  fatal: { label: "Fatal Crashes", fill: "hsl(var(--chart-1))" },
  injury: { label: "Injury Crashes", fill: "hsl(var(--chart-2))" },
  tow: { label: "Tow Crashes", fill: "hsl(var(--chart-3))" },
};

export function CrashStatistics(props: {
  data: { fatal: number; injury: number; total: number; tow: number };
}) {
  const { data } = props;
  const id = "pie-crash-stats";

  return (
    <Card data-chart={id} className="flex flex-col">
      <ChartStyle id={id} config={config} />
      <CardHeader className="flex-row items-start space-y-0 pb-0">
        <div className="grid gap-1">
          <CardTitle>Crash Statistics</CardTitle>
          <CardDescription>All Time</CardDescription>
        </div>
      </CardHeader>
      <Suspense>
        <CardContent className="flex flex-1 justify-center pb-0">
          <ChartContainer
            id={id}
            config={config}
            className="mx-auto aspect-square w-full max-w-[1000px]"
          >
            <PieChart>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel />}
              />
              <Pie
                data={[
                  {
                    category: "fatal",
                    count: data.fatal,
                    fill: "hsl(var(--chart-1))",
                  },
                  {
                    category: "injury",
                    count: data.injury,
                    fill: "hsl(var(--chart-2))",
                  },
                  {
                    category: "tow",
                    count: data.tow,
                    fill: "hsl(var(--chart-3))",
                  },
                ]}
                dataKey="count"
                nameKey="category"
                innerRadius={100}
                strokeWidth={5}
              >
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className="fill-foreground text-3xl font-bold"
                          >
                            {data.total}
                          </tspan>
                          <tspan
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 24}
                            className="fill-muted-foreground"
                          >
                            Total Crashes
                          </tspan>
                        </text>
                      );
                    }
                  }}
                />
              </Pie>
              <ChartLegend
                content={<ChartLegendContent nameKey="category" />}
                className="pb-5"
              />
            </PieChart>
          </ChartContainer>
        </CardContent>
      </Suspense>
    </Card>
  );
}
