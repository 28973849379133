import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "~/components/ui/chart";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import { Suspense } from "react";

const chartConfig = {
  count: { label: "Complaints", color: "hsl(var(--chart-0))" },
  year: {
    label: "Year",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

export function ComplaintsByYear(props: {
  data: { count: number; year: number }[];
}) {
  const { data } = props;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Complaints By Year</CardTitle>
      </CardHeader>
      <Suspense>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <BarChart accessibilityLayer data={data}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="year"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <ChartTooltip cursor={true} content={<ChartTooltipContent />} />
              <Bar dataKey="count" fill="hsl(var(--chart-1))" radius={8} />
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Suspense>
    </Card>
  );
}
