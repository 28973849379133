import React, { Suspense } from "react";

import { Label, Pie, PieChart, Sector } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartStyle,
  ChartTooltip,
  ChartTooltipContent,
} from "~/components/ui/chart";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";

export function ComplaintsByYearCategory(props: {
  data: { count: number; year: number; category: string }[];
}) {
  const { data } = props;
  const id = "pie-interactive-complaints-by-year";
  const [selectedYear, setSelectedYear] = React.useState<string>(
    `${data[0]?.year ?? new Date().getFullYear()}`,
  );

  const slicedData = data.filter((d) => `${d.year}` === selectedYear);
  // add fill based on categories
  // we have chart-1 through chart-5
  const categories = Array.from(new Set(slicedData.map((d) => d.category)));
  const years = Array.from(new Set(data.map((d) => d.year)));
  const colors = categories.map((_, i) => `chart-${(i % 5) + 1}`);
  const dataWithColors = slicedData.map((d, i) => ({
    ...d,
    fill: `hsl(var(--${colors[i % colors.length]}))`,
  }));
  const config = Object.fromEntries(
    categories.map((c, i) => [
      c,
      { label: c, fill: `hsl(var(--${colors[i % colors.length]}))` },
    ]),
  );

  return (
    <Card data-chart={id} className="flex flex-col">
      <ChartStyle id={id} config={config} />
      <CardHeader className="flex-row items-start space-y-0 pb-0">
        <div className="grid gap-1">
          <CardTitle>Complaints By Category</CardTitle>
          <CardDescription>Full Year {selectedYear}</CardDescription>
        </div>
        <Select
          value={`${selectedYear}`}
          onValueChange={(v) => setSelectedYear(v)}
        >
          <SelectTrigger
            className="ml-auto h-7 w-[130px] rounded-lg pl-2.5"
            aria-label="Select a value"
          >
            <SelectValue placeholder="Select month" />
          </SelectTrigger>
          <SelectContent align="end" className="rounded-xl">
            {years.map((key) => {
              return (
                <SelectItem
                  key={key}
                  value={`${key}`}
                  className="rounded-lg [&_span]:flex"
                >
                  <div className="flex items-center gap-2 text-xs">
                    <span />
                    {key}
                  </div>
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </CardHeader>
      <Suspense>
        <CardContent className="flex flex-1 justify-center pb-0">
          <ChartContainer
            id={id}
            config={config}
            className="mx-auto aspect-square h-[80vh] w-full max-w-[1000px] lg:h-auto"
          >
            <PieChart>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel />}
              />
              <Pie
                data={dataWithColors}
                dataKey="count"
                nameKey="category"
                innerRadius={50}
                strokeWidth={5}
              >
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className="fill-foreground text-3xl font-bold"
                          >
                            {selectedYear}
                          </tspan>
                          <tspan
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 24}
                            className="fill-muted-foreground"
                          >
                            Year
                          </tspan>
                        </text>
                      );
                    }
                  }}
                />
              </Pie>
              <ChartLegend
                content={<ChartLegendContent nameKey="category" />}
                className="[&>*]:justify-left grid -translate-y-2 grid-cols-2 gap-2 lg:grid-cols-3 [&>*]:basis-1/4"
              />
            </PieChart>
          </ChartContainer>
        </CardContent>
      </Suspense>
    </Card>
  );
}
