import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  useGeographies,
} from "react-simple-maps";
import { Suspense, useRef, useState } from "react";
import states from "~/states.json";
import allStates from "~/all-states.json";
import { geoCentroid } from "d3-geo";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { Popover, PopoverTrigger } from "./ui/popover";
import { PopoverPortal, PopoverContent } from "@radix-ui/react-popover";
import stateConverter from "us-state-converter";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";

const offsets: Record<string, [number, number]> = {
  RI: [30, 10],
  CT: [20, 20],
  NJ: [20, 5],
  DE: [20, 10],
  DC: [20, 10],
  MI: [10, 20],
  HI: [-20, 0],
  FL: [10, 0],
  LA: [-10, 5],
};

// TODO: add a tooltip on hover to show the statistics
// Or add offsets for certain states
// probably easiest to just do the hover approach
// and just use react-popper to align a tooltip
export function Inner(props: {
  data: {
    complaints: { state: string; count: string }[];
    crashes: { state: string; count: string }[];
    companies: { state: string; count: string }[];
  };
}) {
  const { complaints, crashes, companies } = props.data;
  const { geographies } = useGeographies({
    geography: states,
  });

  const [stateOpen, setStateOpen] = useState<string | null>(null);
  const popoverTriggeredRef = useRef(false);

  return (
    <>
      <Geographies geography={states}>
        {({ geographies }) => {
          return (
            <>
              {" "}
              {geographies
                .filter((geo) => {
                  return allStates.some((state) => state.val === geo.id);
                })
                .map((geo) => {
                  const stateAbbr = allStates.find(
                    (state) => state.val === geo.id,
                  )!.id;
                  const complaintsInState = complaints.find(
                    (c) => c.state === stateAbbr,
                  );
                  const companiesInState = companies.find(
                    (c) => c.state === stateAbbr,
                  );
                  const crashesInState = crashes.find(
                    (c) => c.state === stateAbbr,
                  );
                  return (
                    <>
                      <Popover
                        key={`popover_${geo.id}`}
                        onOpenChange={(open) => {
                          if (open) popoverTriggeredRef.current = true;
                          if (!open) popoverTriggeredRef.current = false;
                          setStateOpen(open ? stateAbbr : null);
                        }}
                        open={stateOpen === stateAbbr}
                      >
                        <Tooltip
                          onOpenChange={(open) => {
                            if (!open && !popoverTriggeredRef.current)
                              setStateOpen(null);
                            if (open && !popoverTriggeredRef.current) {
                              setStateOpen(stateAbbr);
                            }
                          }}
                          open={stateOpen === stateAbbr}
                        >
                          <PopoverPortal>
                            <PopoverContent />
                          </PopoverPortal>
                          <PopoverTrigger asChild>
                            <TooltipTrigger asChild>
                              <g>
                                <Geography
                                  geography={geo}
                                  className="outline-none"
                                  fill="none"
                                  stroke="#FFF"
                                />

                                <Geography
                                  geography={geo}
                                  className="fill-chart-2 outline-none hover:animate-pulse"
                                ></Geography>
                              </g>
                            </TooltipTrigger>
                          </PopoverTrigger>
                          <TooltipPrimitive.Portal>
                            <TooltipContent className="grid min-w-[8rem] items-start gap-1.5 rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs text-foreground shadow-xl">
                              <p>
                                <span className="font-bold">
                                  {stateConverter.fullName(stateAbbr)}{" "}
                                </span>
                              </p>
                              <p>
                                <span className="font-mono font-medium tabular-nums text-foreground">
                                  {companiesInState?.count ?? 0}
                                </span>{" "}
                                companies
                              </p>
                              <p>
                                <span className="font-mono font-medium tabular-nums text-foreground">
                                  {crashesInState?.count ?? 0}
                                </span>{" "}
                                crashes
                              </p>
                              <p>
                                <span className="font-mono font-medium tabular-nums text-foreground">
                                  {complaintsInState?.count ?? 0}
                                </span>{" "}
                                complaints
                              </p>
                            </TooltipContent>
                          </TooltipPrimitive.Portal>
                        </Tooltip>
                      </Popover>
                    </>
                  );
                })}
            </>
          );
        }}
      </Geographies>
      {geographies
        .filter((geo) => {
          return allStates.some((state) => state.val === geo.id);
        })
        .map((geo) => {
          const centroid = geoCentroid(geo.geometry);

          let centroidCoords =
            typeof centroid === "string"
              ? (centroid as any).split(",")
              : centroid;

          const stateAbbr = allStates.find((state) => state.val === geo.id)!.id;
          const offset = offsets[stateAbbr] ?? [0, 0];

          return (
            <Marker
              coordinates={centroidCoords}
              key={`marker_${geo.id}`}
              id={geo.id}
            >
              <text
                x={offset[0]}
                y={offset[1]}
                className="text-xs"
                textAnchor="middle"
              >
                {stateAbbr}
              </text>
            </Marker>
          );
        })}
    </>
  );
}

export function StateStatistics(props: {
  data: {
    complaints: { state: string; count: string }[];
    crashes: { state: string; count: string }[];
    companies: { state: string; count: string }[];
  };
}) {
  return (
    <Card suppressHydrationWarning>
      <CardHeader className="flex-row items-start space-y-0 pb-0">
        <div className="grid gap-1">
          <CardTitle>Complaints, Companies, and Crashes by State</CardTitle>
          <CardDescription>
            Hover or click on a state to reveal the information.
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent className="flex h-[500px] flex-1 justify-center pb-0">
        <Suspense fallback={<div>Loading...</div>}>
          <TooltipProvider>
            <ComposableMap projection="geoAlbersUsa">
              <Inner data={props.data} />
            </ComposableMap>
          </TooltipProvider>
        </Suspense>
      </CardContent>
    </Card>
  );
}
